import { Component, Inject } from '@angular/core';
import { Platform } from '@ionic/angular';
// import { FcmService } from './services/fcm.service';
import { AppConfig, APP_CONFIG } from './app.config';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../environments/environment';
import { EventsService } from './events.service';
import { UtilsService } from './services/utils.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  scannerStatus: boolean;
  constructor(
    @Inject(APP_CONFIG) public config: AppConfig,
    private platform: Platform,
    // private fcmService: FcmService,
    private translate: TranslateService,
    public events: EventsService,
    public _utils: UtilsService,
  ) {
    this.initializeApp();
  }
  async ngOnInit() {
    // If using a custom driver:
    // await this.storage.defineDriver(MyCustomDriver)
    this.events.getScannerStatus().subscribe((data) => {
      this.scannerStatus = data;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      // this.statusBar.backgroundColorByHexString('#53b748');
      // this.statusBar.styleLightContent();
      // this.splashScreen.hide();
      // firebase notifications service init.
      // this.fcmService.initPush();
      let defaultLang = environment.defaultLanguage;
      // this.globalize(defaultLang);
      this.translate.setDefaultLang('en');
      this._utils.appLangugage$.subscribe((code) => {
        console.log('App Language used: ', code);
        this.translate.use(code);
      });
    });
  }

  closeScan() {
    this.events.scannerStatus(false);
    this.scannerStatus = false;
  }
}
