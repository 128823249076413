import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Subject, ReplaySubject } from 'rxjs';
import { LoginStorage } from 'src/constants/storage.enum';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  firstTime: boolean;
  authToken = new ReplaySubject<string>(1);
  private _storage: Storage | null = null;
  constructor() {
    this.init();
  }

  get accessToken() {
    return this.authToken;
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    const storage = await Preferences.set({
      key: 'user',
      value: JSON.stringify({
        id: 1,
        name: 'Max',
      }),
    });
    this.checkToken();
  }

  // Create and expose methods that users of this service can
  // call, for example:
  async set(key: string, value: string): Promise<any> {
    return await Preferences?.set({ key, value });
  }

  async checkToken() {
    const accessToken = await Preferences.get({ key: LoginStorage.accessToken });
    this.authToken.next(accessToken.value);
    return accessToken;
  }

  public async get(key: string) {
    const res = await Preferences?.get({ key });
    return res.value;
  }

  public rem(key: string) {
    return Preferences?.remove({ key });
  }

  async saveFirstTimeLoad(): Promise<any> {
    await Preferences.set({ key: 'firstTime', value: JSON.stringify(true) });
    console.log('firstTime set to true');
  }

  async isFirstTimeLoad(): Promise<any> {
    const result = await Preferences.get({ key: 'firstTime' });
    console.log('result', result.value);
    if (result.value != null) {
      this.firstTime = false;
    } else {
      this.firstTime = true;
    }
    return this.firstTime;
  }
  public clearStorage() {
    return Preferences?.clear();
  }
}
