export enum LoginStorage {
  phoneNumber = 'phoneNumber',
  loginOtp = 'loginOtp',
  reseller = 'reseller',
  user = 'user',
  accessToken = 'accessToken',
  fbToken = 'firebaseToken',
  token = 'token',
}

export enum StorageObjects {
  currentSelectedLocation = 'CURRENT_SELECTED_LOCATION',
  userObj = 'user',
  selectedStore = 'SELECTED_STORE',
  isEmptyAddress = 'isEmptyAddress',
  fcmToken = 'fcm_token',
  noStores = 'NO_STORES',
}
