import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { B2BOrderPaymentStatus, B2BOrderStatus, B2BPaymentTypes } from 'src/generated/graphql';

export const OrderStatusLabel = {
  ACCEPTED: 'Accepted',
  DB_ASSIGNED: 'Delivery Boy Assigned',
  DELIVERED: 'Delivered',
  DRAFT: 'Draft',
  IN_WAREHOUSE: 'In Warehouse',
  OUTBOUND: 'Outbound',
  PACKED: 'Packed',
  REJECTED: 'Rejected',
  RETURNED: 'Returned',
};

export const OrderStatusColor = {
  ACCEPTED: 'orange',
  DB_ASSIGNED: 'green',
  DELIVERED: 'green',
  DRAFT: 'orange',
  IN_WAREHOUSE: 'orange',
  OUTBOUND: 'red',
  PACKED: 'green',
  REJECTED: 'red',
  RETURNED: 'red',
};

export const PaymentStatusLabel = {
  COD: 'Cash on Delivery',
  FAILURE: 'Failed',
  PENDING: 'Pending',
  RUPIFI_PAYMENT_CAPTURED: 'Rupifi Captured',
  RUPIFI_PAYMENT_CREATED: 'Rupifi Created',
  RUPIFI_PAYMENT_VOID: 'Rupifi Void',
  SUCCESS: 'Success',
};

export const PaymentStatusColor = {
  COD: 'green',
  FAILURE: 'red',
  PENDING: 'orange',
  RUPIFI_PAYMENT_CAPTURED: 'orange',
  RUPIFI_PAYMENT_CREATED: 'orange',
  RUPIFI_PAYMENT_VOID: 'red',
  SUCCESS: 'green',
};

export const PaymentTypeLabel = {
  COD: 'COD',
  Card: 'Card',
  Cash: 'Cash',
  Online: 'Online',
  UPI: 'UPI',
};

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  public pageTitle$ = new BehaviorSubject('Home');
  public appLangugage$ = new BehaviorSubject('en');
  constructor() {}

  public setTitle(title: string) {
    this.pageTitle$.next(title);
  }

  public setAppLanguage(code: string) {
    this.appLangugage$.next(code);
  }

  public orderStatus(status: B2BOrderStatus | string): string {
    return OrderStatusLabel[status] || '-';
  }

  public orderClass(status: B2BOrderStatus | string): string {
    return OrderStatusColor[status] || 'orange';
  }

  public paymentStatus(status: B2BOrderPaymentStatus | string): string {
    return PaymentStatusLabel[status] || '-';
  }

  public paymentClass(status: B2BOrderPaymentStatus | string): string {
    return PaymentStatusColor[status] || 'orange';
  }

  public paymentType(status: B2BPaymentTypes | string): string {
    return PaymentTypeLabel[status] || '-';
  }
}
