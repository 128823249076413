import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subscription } from 'rxjs';
import { GetStoreGQL, SalesPersonLoginGQL, TriggerSalesPersonLoginGQL } from '../../generated/graphql';
import { Apollo } from 'apollo-angular';
import { map } from 'rxjs/operators';
import { StoreModel } from '../core/adapters/models/store-model/store-model';
import { StaticUtils } from '../core/adapters/static-utils/static-utils';
@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  authenticationState = new BehaviorSubject(false);
  storeSelectState = new BehaviorSubject(false);
  authToken = new ReplaySubject<string>(1);

  private tokenValue = '';
  subscriptions: Subscription[] = [];

  public get token(): string {
    return this.tokenValue;
  }
  public set token(t) {
    this.tokenValue = t;
  }

  private fbTokenValue = '';
  public get fbToken(): string {
    return this.fbTokenValue;
  }
  public set fbToken(t) {
    this.fbTokenValue = t;
  }

  store = new StoreModel();

  constructor(private apollo: Apollo) {}

  triggerLogin(payload: number): Observable<any> {
    const gql = new TriggerSalesPersonLoginGQL(this.apollo);
    return gql.mutate({
      input: payload,
    });
  }

  signIn(payload: { otp: string; phone: number }): Observable<any> {
    const gql = new SalesPersonLoginGQL(this.apollo);
    return gql
      .mutate({
        ...payload,
      })
      .pipe(
        map(async (response) => {
          this.setToken(response);
          return this.setData(response);
        }),
      );
  }

  me(): Observable<any> {
    try {
      const data = JSON.parse(StaticUtils.storage.getItem(StaticUtils.localStorageSelectedStore) || '');

      if (data.store) {
        this.store = new StoreModel(data.store);
      }
    } catch (e) {
      console.error('Me error: ', e);
    }

    return of({});
  }

  logout(): Observable<any> {
    this.token = '';
    this.fbToken = '';
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    StaticUtils.storage.clear();
    if(isLoggedIn === "true") {
      localStorage.setItem("isLoggedIn", "true");
    }
    return of({});
  }
  get authState() {
    return this.authenticationState.asObservable();
  }

  get hasStoreSelected() {
    return this.storeSelectState.asObservable();
  }
  // Create and expose methods that users of this service can
  checkToken() {
    const token = StaticUtils.storage.getItem(StaticUtils.localStorageTokenName);
    const store = StaticUtils.storage.getItem(StaticUtils.localStorageSelectedStore);
    if (token) {
      this.authenticationState.next(true);
      this.authToken.next(token);
    } else {
      this.authenticationState.next(false);
    }
    if (store) {
      this.storeSelectState.next(true);
    } else {
      this.storeSelectState.next(false);
    }
  }

  isAuthenticated() {
    return this.authenticationState.value;
  }

  clearToken() {
    this.authenticationState.next(false);
    this.authToken.next(null);
  }

  private async setData(response: any) {
    if (response?.data?.SalesPersonLogin?.loginToken) {
      StaticUtils.storage.setItem(
        StaticUtils.localStoragetoken,
        JSON.stringify({
          tokens: response.data.SalesPersonLogin,
        }),
      );
    }

    return response;
  }

  private async setToken(response: any) {
    if (response?.data?.SalesPersonLogin?.loginToken) {
      this.token = response.data.SalesPersonLogin.loginToken;
      this.fbToken = response.data.SalesPersonLogin.fbToken;
      StaticUtils.storage.setItem(StaticUtils.localStorageUserName, JSON.stringify(this.decodeJWTToken(this.token)));
      StaticUtils.storage.setItem(StaticUtils.localStorageTokenName, this.token);
      StaticUtils.storage.setItem(StaticUtils.localStorageFbTokenName, this.fbToken);
    }
  }

  decodeJWTToken(token) {
    const _decodeToken = (token) => {
      try {
        return JSON.parse(atob(token));
      } catch {
        return;
      }
    };
    return token
      .split('.')
      .map(token => _decodeToken(token))
      .reduce((acc, curr) => {
        if (!!curr) acc = { ...acc, ...curr };
        return acc;
      }, Object.create(null));
    }

  ngOnDestroy(): void {
    this.subscriptions.forEach((item) => item.unsubscribe());
  }
}
