import { Directive, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
import { DomController } from '@ionic/angular';

enum Direction {
  UP = 1,
  DOWN = 0,
}
@Directive({
  selector: '[appHideHeader]',
})
export class HideHeaderDirective implements OnInit {
  @Input('header') header: any;
  @Input('searchToolbar') searchToolbar: any;
  @Input('searchIcon') searchIcon: any;
  @Input('defCart') defCart: any;

  readonly scrollDistance = 50;
  previousY = 0;
  direction: Direction = Direction.DOWN;
  saveY = 0;
  constructor(private renderer: Renderer2, private domCtrl: DomController) {}

  ngOnInit(): void {
    this.header = this.header.el;
    this.searchToolbar = this.searchToolbar.el;
    this.searchIcon = this.searchIcon.el;
    this.defCart = this.defCart.el;
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.header, 'transition', 'margin-top 700ms');
      // this.renderer.setStyle(this.searchToolbar, 'transition', 'width 400ms');
      this.renderer.setStyle(this.searchIcon, 'transition', 'visibility 0s, opacity 0.5s linear');
      this.renderer.setStyle(this.defCart, 'transition', 'visibility 0s, opacity 0.5s linear');
    });
  }

  @HostListener('ionScroll', ['$event']) onContentScroll($event: any) {
    console.log(
      '🚀 ~ file: hide-header.directive.ts ~ line 102 ~ HideHeaderDirective ~ @HostListener ~ this.direction',
      this.direction,
    );

    if ($event.detail.currentY <= 0 || $event.detail.currentY == this.saveY) {
      return;
    }

    const scrollTop: number = $event.detail.scrollTop;
    let newDirection = Direction.DOWN;

    // Calculate the distance from top based on the previousY
    // which is set when we change directions
    let newPosition = -scrollTop + this.previousY;

    // We are scrolling up the page
    // In this case we need to reduce the position first
    // to prevent it jumping from -50 to 0
    if (this.saveY > $event.detail.currentY) {
      newDirection = Direction.UP;
      newPosition -= this.scrollDistance;
    }

    // Make our maximum scroll distance the end of the range
    if (newPosition < -this.scrollDistance) {
      newPosition = -this.scrollDistance;
    }

    // Calculate opacity between 0 and 1
    let newOpacity = 1 - newPosition / -this.scrollDistance;
    // if ($event.detail.scrollTop == 0) {
    //   widthPosition = 100;
    // } else {
    //   widthPosition = 90;
    // }
    if (this.direction == 1) {
      this.domCtrl.write(() => {
        // this.renderer.setStyle(this.searchToolbar, 'width', widthPosition + '%');
        this.renderer.setStyle(this.searchIcon, 'display', 'none');
        this.renderer.setStyle(this.searchIcon, 'opacity', '0');
        this.renderer.setStyle(this.defCart, 'display', 'block');
        this.renderer.setStyle(this.defCart, 'opacity', '1');
        // this.renderer.setStyle(this.header, "opacity", newOpacity);
      });
    } else {
      this.domCtrl.write(() => {
        // this.renderer.setStyle(this.header, 'top', Math.min(0, newPosition) + 'px');
        // this.renderer.setStyle(this.searchToolbar, 'width', widthPosition + '%');
        this.renderer.setStyle(this.searchIcon, 'display', 'block');
        this.renderer.setStyle(this.searchIcon, 'opacity', '1');
        this.renderer.setStyle(this.defCart, 'display', 'none');
        this.renderer.setStyle(this.defCart, 'opacity', '0');
        // this.renderer.setStyle(this.header, "opacity", newOpacity);
      });
    }
    // Move and set the opacity of our element
    this.domCtrl.write(() => {
      this.renderer.setStyle(this.header, 'top', Math.min(0, newPosition) + 'px');
      // this.renderer.setStyle(this.searchToolbar, 'width', widthPosition + '%');
      // this.renderer.setStyle(this.searchIcon, 'display', 'block');
      // this.renderer.setStyle(this.defCart, 'display', 'none');
      // this.renderer.setStyle(this.header, "opacity", newOpacity);
    });
    // this.domCtrl.write(() => {
    // this.renderer.setStyle(this.header, 'top', Math.min(0, newPosition) + 'px');
    // this.renderer.addClass(this.searchToolbar, 'smallSearch');
    // this.renderer.setStyle(this.header, "opacity", newOpacity);
    // });

    // Store the current Y value to see in which direction we scroll
    this.saveY = $event.detail.currentY;

    // If the direction changed, store the point of change for calculation
    if (newDirection != this.direction) {
      this.direction = newDirection;
      this.previousY = scrollTop;
    }
  }
}
