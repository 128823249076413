// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // dailyBeeGQL: 'http://localhost:8080/graphql', // LOCAL SERVER
  // dailyBeeGQL: 'https://db-be-dev-qpvrxjuuzq-el.a.run.app/graphql', // DEV SERVER
  dailyBeeGQL: 'https://prod-stores.dlyb.in/graphql', // STG SERVER
  // dailyBeeGQL: 'https://db-be-prod-qpvrxjuuzq-el.a.run.app/graphql', // PROD SERVER
  // dailyBeeGQL: 'https://main--dailybee-backend-prod.apollographos.net/graphql', // PROD APOLLO SERVER
  defaultLanguage: 'en',
  codepushAndroidKey: '0U8vLFuFc4TfD_fyAppRzlcB40WVsrEAL0EBX',
  codePushIosKey: '',
  appversion: '1.4.2',
  versionCode: 1,
  versionName: '1.0.0',
  updateText: 'STG V1.0.0',

  isDebugMode: true,
  enableSocket: false,
  socketBaseURL: '',
  firebaseSenderId: '895049579222',
  vapidKey: 'BPYwU6G4-cCuL3Tp5jCPuLZ-L93A7u9cWgKMZ3PEq-LbSIyF0Y3YLTl1dsqQA1_xPGjAs4eUeKtQ0oOAVcHv77E',

  firebaseConfig: {
    apiKey: 'AIzaSyB6p8jW8SdTX-FWV9bE30E1L7jtm2yQyx4',
    authDomain: 'daily-bee-dev.firebaseapp.com',
    projectId: 'daily-bee-dev',
    storageBucket: 'daily-bee-dev.appspot.com',
    messagingSenderId: '895049579222',
    appId: '1:895049579222:web:df8b3b3a66c6235474a777',
    measurementId: 'G-X7MVM9HWRB',
  },
  baseUrl: 'https://assets.dlyb.in/',
  minStockReq: 3,
  maxQuantity: 50,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
