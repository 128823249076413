import { InjectionToken } from "@angular/core";

export let APP_CONFIG = new InjectionToken<AppConfig>("app.config");

export interface AppConfig {
  availableLanguages: Array<{
    code: string;
    name: string;
    placeholder: string;
  }>;
  demoMode: boolean;
}

export const BaseAppConfig: AppConfig = {
  availableLanguages: [
    {
      code: "en",
      name: "English",
      placeholder: "Hi, Welcome",
    },
    {
      code: "telugu",
      name: "Telugu",
      placeholder: "Hi, Welcome",
    },
  ],
  demoMode: false,
};

export const DefaultLocation = {
  lat: 17.97110402444496,
  lng: 79.59378510812326
}

export const SCROLLABLE_BANNER_CODE = 'BANNER_1';
export const STATIC_BANNER_CODE = 'BANNER_2';