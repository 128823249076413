import { Injectable, Injector, OnDestroy } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, Subscription, throwError } from 'rxjs';
import { AuthenticationService } from '../services/auth.service';
import { environment } from 'src/environments/environment';
import { StorageService } from '../services/storage-service';
import { LoginStorage } from 'src/constants/storage.enum';
import { NavController } from '@ionic/angular';
import { map } from 'rxjs/operators';
import { StaticUtils } from './adapters/static-utils/static-utils';

@Injectable()
export class JwtInterceptor implements HttpInterceptor, OnDestroy {
  tokenAccess: string;
  jwtToken: string;
  getUserTriggered: boolean;
  subscriptions: Subscription[] = [];
  authService: AuthenticationService;

  constructor(
    private injector: Injector,
    private storageService: StorageService,
    private navCtrl: NavController
  ) {
    this.authService = this.injector.get(AuthenticationService);
    setTimeout(() => this.checkToken(), 1000);
      this.jwtToken = this.authService.token;
  }

  checkToken() {
    const token = StaticUtils.storage.getItem(StaticUtils.localStorageTokenName);
      if (token) {
        this.jwtToken = token;
      } else {
        console.log('NO TOKEN FOUND!!');
        this.navCtrl.navigateRoot('/auth/login');
      }
  }

  // intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  //   // const isApiUrl = request.url === environment.dailyBeeGQL;
  //   // if (isApiUrl && this.jwtToken) {
  //     request = request.clone({
  //       setHeaders: {
  //         Authorization: `Bearer ${this.authService && this.authService.token}`
  //       },
  //     });
  //   // } else {
  //   //   console.log('NO HEADERS !!');
  //   // }

  //   return next.handle(request).pipe(
  //     map(res => {
  //       const error = res && res['body'] && res['body'].errors && res['body'].errors[0];
  //       if (error && error.message === 'Unauthorized') {
  //         localStorage.clear();
  //         this.storageService.clearStorage();
  //         // this.authService.clearToken();
  //         throwError('Unauthorized');
  //       } else {
  //         return res;
  //       }
  //     })
  //   )
  // }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {

    let headers = {};
    const token = StaticUtils.storage.getItem(StaticUtils.localStorageTokenName);
    if (request.url.indexOf('/sign-in') === -1) {
      headers = {
        Authorization: `Bearer ${token}`
      };
    }

    const authRequest = request.clone({
      setHeaders: headers
    });

    return next.handle(authRequest).pipe(
          map(res => {
            const error = res && res['body'] && res['body'].errors && res['body'].errors[0];
            if (error && error.message === 'Unauthorized') {
              localStorage.clear();
              this.storageService.clearStorage();
              this.authService.clearToken();
              throwError('Unauthorized');
            } else {
              return res;
            }
          })
        );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(item => item.unsubscribe())
  }
}
