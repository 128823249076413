import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth-guard.service';
const routes: Routes = [
  {
    path: '',
    redirectTo: 'splash',
    pathMatch: 'full',
  },

  {
    path: 'auth',
    loadChildren: () => import('./auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then((m) => m.IntroPageModule),
  },
  {
    path: 'splash',
    loadChildren: () => import('./auth/loadingscreen/loadingscreen.module').then((m) => m.SpalshPageModule),
  },
  {
    path: 'pages',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./pages/pages.module').then( m => m.PagesPageModule)
  },

];

  @NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule {}