import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  constructor() {}

  private barcodeScanner = new Subject<any>();

  scannerStatus(data: boolean) {
    this.barcodeScanner.next(data);
  }

  getScannerStatus(): Subject<any> {
    return this.barcodeScanner;
  }
}
