import * as moment from "moment";
import { StaticUtils } from "../../static-utils/static-utils";

export class StoreModel {
    _id = ''
    categories = []
    isActive = true
    ownerName = ""
    ownerPrimaryPhone = 0
    ownerSecondaryPhone = 0
    referralCode = ""
    storeName = ""
    salesStoreStatus = ""
    address = {
        addressLine1: "",
        addressLine2: "",
        city: "",
        coord: { type: "Point", coordinates: [0, 0], __typename: "LocationCoordType" },
        phone: "",
        pinCode: "",
        state: "",
        __typename: "AddressType"
    }
    constructor(raw?: StoreModel) {
        if (!raw) {
            return;
        }

        this._id = raw?._id
        this.categories = raw?.categories
        this.isActive = raw?.isActive
        this.ownerName = raw?.ownerName
        this.ownerPrimaryPhone = raw?.ownerPrimaryPhone
        this.ownerSecondaryPhone = raw?.ownerSecondaryPhone
        this.referralCode = raw?.referralCode
        this.storeName = raw?.storeName
        this.address = raw?.address
        this.salesStoreStatus = raw?.salesStoreStatus || ""
    }
}
