import { environment } from 'src/environments/environment';

export class StaticUtils {
  static baseURL = environment.dailyBeeGQL;
  static isDebugMode = environment.isDebugMode;

  static fbSenderId = environment.firebaseSenderId;
  static vapidKey = environment.vapidKey;
  static fbConfig = environment.firebaseConfig;

  static localStorageSelectedStore = btoa('store-info'); // used for selected store.
  static localStoragetoken = btoa('store-token'); // used for Auth token.
  static localStorageTokenName = btoa('store-pwa-tkn'); // used for Auth token.
  static localStorageFbTokenName = btoa('store-pwa-fbtkn'); // used for Auth token.
  static localStorageDataName = btoa('store-pwa'); // used for app data.
  static localStorageUserName = btoa('user-name'); // used for app data.

  static enableSocket = environment.enableSocket;
  // static socketBaseURL = environment.socketBaseURL;

  static googleMapDirectionURL = 'https://maps.google.com/?';

  static formatNumber = (num: any, showCurrency: boolean = true, isFixed: boolean = false): string => {
    if (isNaN(num)) {
      return num;
    }

    return (showCurrency ? '₹ ' : '') + (isFixed ? num.toFixed(2) : num).toLocaleString('en-IN');
  };

  static dateTimeFormat = 'DD/MM/YYYY hh:mm A';
  static dateFormat = 'DD/MM/YYYY';

  static storage = window.localStorage || window.sessionStorage;
  static roundOff(num: any, decimalPoints: number = 2): number {
    if (isNaN(num)) {
      return 0;
    }

    return parseFloat(parseFloat(num).toFixed(decimalPoints));
  }
}

export const PAGE_SIZE = 12;
